<template>
  <div class="footer_box">
    <div class="footer">
      <div class="footer_logo">
        <div class="author">Copyright © 2018-2022 100Swap.net / Ai100 Automated trading system</div>
        <ul class="contact">
          <li class="contact_item">
            <i class="iconfont icon-twitter-circle-fill"></i>
          </li>
          <li class="contact_item">
            <i class="iconfont icon-weibo-circle-fill"></i>
          </li>
          <li class="contact_item">
            <i class="iconfont icon-github"></i>
          </li>
          <li class="contact_item">
            <i class="iconfont icon-telegram1"></i>
          </li>
          <li class="contact_item">
            <i class="iconfont icon-facebookfacebook521"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      
    }
  },
  methods: {
    
  },
  mounted () {
  }
}
</script>

<style lang="less">
@import '../../assets/styles/varibles.less';
.footer_box{
  width: 100%;
  z-index: 5;
  background: @bgColor2;
  border-top: 5px solid @bgColor1;
  .footer{
    margin: 0 auto;
    padding:  20px 0  60px 0;
    max-width: @maxWidth;
    min-width: @minWidth;
    .footer_logo{
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .author{
      color: rgba(255,255,255,0.6);
      margin: 20px 0 18px;
      font-size: 0.857rem;
      font-weight: @fontWeight[400];
      letter-spacing: 0.004em;
    }
    .author-footer{
      color: rgba(255,255,255,0.6);
      font-size: 0.857rem;
      margin:0 0 20px 0;
      font-weight: @fontWeight[400];
      letter-spacing: 0.004em;
    }
    .contact{
      width: 246px;
      display: flex;
      flex-wrap: wrap;
      .contact_item{
        margin: 0 10px;
        i{
          font-size: @font22;
          color: #D3D5D6;
        }
      }
    }
  }
  .footer_content{
    flex: 6;
    display: flex;
    .content_item{
      display: flex;
      flex: 1;
      flex-direction: column;
      .item_tit{
        color: #F4F4F5;
        margin: 0 0 18px;
        padding: 0 0 0 4px;
        font-size: @font12;
        border-left: 2px solid #FFB400;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.004em;
      }
      .item_desc{
        color: rgba(255,255,255,0.6);
        font-size: @font12;
        transition: all 0.2s ease-in-out;
        font-weight: 400;
        letter-spacing: 0.004em;
        cursor: pointer;
        margin-bottom: 12px;
      }
      .item_desc:hover{
        color: #fff;
      }
    }
  }
}
</style>
