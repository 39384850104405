<template>
<div>
  <div class="ai100_header">
    <div class="header_container">
      <div class="left">
        <div class="tit">
          <img src="@/assets/images/ai1002.png" width="167" height="65">
        </div>
        <div class="text">
          <div class="en">Digital Assets</div>
          <div class="en2">Automated trading system</div>
        </div>
      </div>
      <div class="right" v-if="has!=1">
        <el-button type="primary" class="tool_btn" @click="MyToolClick">{{$t('lang.我的工具系统')}}</el-button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import './Index.less'
export default {
  name: "Ai100Header",
  props: {
    has: Number
  },
  methods: {
    MyToolClick () {
      // this.$router.push('/MyToolSystem');
      // this.$router.push('/MyToolSystem');
    //  console.log(localStorage.mobile)
     if(localStorage.orderId){
      this.$router.push('/RobotSetDetails');
     }else{
      this.$router.push('/');
     }
    }
  }
};
</script>
