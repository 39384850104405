<template>
  <div class="sys_robot_details"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.6)">
    <Header :id="2"></Header>
    <Ai100Header :has="1"></Ai100Header>
    <div class="details_box">
      <div class="main_container">
        <div  class="fund_box">
          <div class="fund_top">
            <div class="top_l">
              <div class="fund_item">
                <div class="title" v-if="this.$route.query.id==1">
                  <div class="text">
                    <div class="text1">{{$t('lang.systemList.item1.title1')}}</div>
                    <div class="text2">{{$t('lang.systemList.item1.title2')}}</div>
                  </div>
                </div>
                <div class="title" v-if="this.$route.query.id==2">
                  <div class="text">
                    <div class="text1">{{$t('lang.systemList.item2.title1')}}</div>
                    <div class="text2">{{$t('lang.systemList.item2.title2')}}</div>
                  </div>
                </div>
                <div class="title" v-if="this.$route.query.id==3">
                  <div class="text">
                    <div class="text1">{{$t('lang.systemList.item3.title1')}}</div>
                    <div class="text2">{{$t('lang.systemList.item3.title2')}}</div>
                  </div>
                </div>
                <div class="item_lay">
                  <el-row type="flex" justify="space-between" class="item_flex">
                    <el-col :span="11">
                      <div class="item_con">
                        <span class="desc">系统类型</span>
                        <span class="num">全智能网格交易</span>
                      </div>
                    </el-col>
                    <el-col :span="11">
                      <div class="item_con">
                        <span class="desc">接入方式</span>
                        <span class="num">API 接入</span>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row type="flex" justify="space-between" class="item_flex">
                    <el-col :span="11">
                      <div class="item_con">
                        <span class="desc">支持平台</span>
                        <img src="@/assets/images/fund.png" width="150" height="20" v-if="this.$route.query.id==1||this.$route.query.id==2">
                        <img src="@/assets/images/fund1.png" width="150" height="20" v-if="this.$route.query.id==3">
                      </div>
                    </el-col>
                    <el-col :span="11">
                      <div class="item_con">
                        <span class="desc">线程总量</span>
                        <span class="num">{{aiHundredCount}} IP</span>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row type="flex" justify="space-between" class="item_flex">
                    <el-col :span="11">
                      <div class="item_con">
                        <span class="desc">服务方式</span>
                        <div class="select_flex">
                          <el-select v-model="service" placeholder="请选择" @change="change_service">
                            <el-option
                              v-for="item in options_service"
                              :key="item.label"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="11">
                      <div class="item_con">
                        <span class="desc">支付方式</span>
                        <div class="select_flex">
                          <el-select v-model="pay_style" placeholder="请选择" @change="change_style">
                            <el-option
                              v-for="item in pay_style_options"
                              :key="item.label"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row type="flex" justify="space-between" class="item_flex">
                    <el-col :span="24">
                      <div class="item_con">
                        <span class="desc">支付总额</span>
                        <span class="pay_num">{{Number(payNum).toFixed(2)}} USDT</span>
                        <div class="little_text">钱包可用余额 {{balance}} USDT</div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row type="flex" justify="space-between">
                    <el-col :span="24">
                      <div class="btn" @click="confirm_buy" :disabled="disabled">确认使用</div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="top_r">
              <div id="line2"></div>
              <div class="detail_con">
                USDT本位主流币现货自动交易系统，是有Ai100联合BQAI等多家机构开发的数字资产量化交易系统。该系统是以USDT（金本位）作为计价单位，与主流币作为标的，进行量化的自动交易工具。此系统可根据个人喜好进行不同的参数设置，形成不同的交易风格，具备可编辑能力，并且操作界面简单，数据展示清晰，支持用户跟随主程序自动交易，支持多个平台的API绑定。该系统经过长期的验证和使用，收益稳定，风险极低，适用于大中小各种体量的交易与投资。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Ai100Header from '../../components/Ai100Header/Index'
import { echartsvalue, aiHundredInfo, aiHundredOrderInsert } from '../../interface.js'
import moment from 'moment'
export default {
  name: 'SysRobotDetails',
  components: {
    Header,
    Ai100Header,
    Footer,
  },
  data() {
    return {
      dialogVisible: false,
      buy_amount: '',
      options_service: [{//服务方式
        value: '1',
        label: '会员免费'
      }, {
        value: '2',
        label: '租赁服务'
      }, {
        value: '3',
        label: '收益抵扣'
      }],
      service: '1',
      pay_style_options: [{//支付方式
        value: '1',
        label: 'USDT'
      }, {
        value: '2',
        label: 'SUNT'
      }],
      pay_style: '1',
      options_time: [{//时长
        value: '365',
        label: '365天'
      }, {
        value: '60',
        label: '60天'
      }, {
        value: '90',
        label: '90天'
      }, {
        value: '120',
        label: '120天'
      }, {
        value: '180',
        label: '180天'
      }],
      time: '365',
      balance: '0.00',
      aiHundredCount: '0',
      residueCount: '0',
      serviceStatus: '0',
      expectAnnual: '0',
      loading: false,
      payNum: 0,
      disabled: false
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
      .then(_ => {
        done();
      })
      .catch(_ => {});
    },
    change_service (val) {//服务方式切换
      this.service = val;
      if(val==2){
        // if(this.common.baseInfo.userId=='665159673435154688'){
          this.payNum = 199;
          // this.$alert('暂未开放，敬请期待', '提示', {
          //   confirmButtonText: '确定',
          // })
          // this.service = '1'
        
      }
      if(val==1){
        this.payNum = 0;
      }
    },
    change_style (val) {//支付方式切换
      this.pay_style = val;
    },
    change_time (val) {//服务时长切换
      this.time = val;
    },
    confirm_buy () {
      this.$confirm('确认使用该策略吗？', '确认信息', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
      })
        .then(() => {
          this.loading = true;
          this.disabled = true;
          var duration = this.service==1?0:this.time;
          aiHundredOrderInsert(this.common.baseInfo.userId,this.service,res=>{
          // aiHundredOrderInsert('697134315943051520',this.service,res=>{
            // console.log(res)
            if(res.code==0){
              if(this.service=='1'){
                this.$alert('购买成功','提示', {
                  confirmButtonText: '确定',
                },()=>{
                  this.loading = false;
                  this.disabled = false;
                  this.$router.push("/Ai100");
                })
              }else if(this.service=='2'){
                this.loading = false;
                this.disabled = false;
                window.location.href = 'https://www.100sunex.io/paymentRobot'+'?orderid='+res.data.orderId;
              }
              
            }else{
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
              })
              this.loading = false;
              this.disabled = false;
            }
          })
        })
        .catch(() => {
          
        });
    },
    getEchartInfo () {

      echartsvalue(res=>{
        // console.log(res)
        if(res.code==200){
          var earnings = res.earnings;
          var principal = res.principal;
          var data1_priceval = [];
          var data1_timedate = [];
          earnings.map(item => {
            if(item.typeval==1){
              data1_priceval.push(item.priceval.slice(0,-1));
              data1_timedate.push(moment(item.timedate*1000).format("MM.DD"));
            }
          })
         
          var data2_priceval = [];
          var data2_timedate = [];
          principal.map(item => {
            if(item.typeval==1){
              data2_priceval.push(item.priceval.slice(0,-1));
              data2_timedate.push(moment(item.timedate*1000).format("MM.DD"));
            }
          })
          // console.log(data2_priceval)
          // console.log(data2_timedate)
          this.$chart.line2('line2',data1_priceval,data2_priceval,data2_timedate);
        }
      })
    },
    aiHundredInfo () {//697134315943051520
      aiHundredInfo ('697134315943051520',res=>{
      // aiHundredInfo ('697134315943051520',res=>{
        if(res.code==0){
          var data = res.data;
          this.balance = Number(data.aiHundredAccount).toFixed(4);//账户余额
          this.residueCount = Number(data.residueCount).toFixed(0); // 剩余线程
          this.aiHundredCount = Number(data.aiHundredCount).toFixed(0); //线程总量
          this.serviceStatus = data.serviceStatus;//服务状态
          this.expectAnnual = Number(data.expectAnnual).toFixed(2);//预取年化
        }else{
          this.$alert('请登录后操作','提示', {
            confirmButtonText: '确定',
          })
        }
      })
    }
  },
  mounted () {
    this.getEchartInfo ();
    this.aiHundredInfo ();
  },
  created ()  {
    if(localStorage.token==""||localStorage.token==null||localStorage.token==undefined){     
        this.$router.push('/');
    }
  }
}
</script>

<style lang="less">
@import '../../assets/styles/varibles.less';
.sys_robot_details{
  .details_box{
    width: 100%;
    min-height: calc(100% - 286px);
    overflow: hidden;
    padding-bottom: 80px;
    .main_container{
      min-width: @minWidth;
      max-width: @maxWidth;
      margin: 0 auto;
      .fund_box{
        padding: 36px 0;
        .fund_top{
          width: 100%;
          display: flex;
          justify-content: space-between;
          .top_l{
            display: flex;
            flex: 1;
            padding-right: 49px;
            border-right: 1px solid @borderColor;
          }
          .top_r{
            padding-left: 49px; 
            width: 599px;
            #line2{
              width: 550px;
              height: 386px;
            }
            .detail_con{
              width: 100%;
              font-size: @font12;
              color: rgba(0,0,0,0.6);
              margin-top: 16px;
              text-align: justify;
            }
          }
          .fund_item{
            width: 100%;
            .title{
              display: flex;
              align-items: center;
              margin-bottom: 14px;
              .logo{
                margin-right: 12px;
              }
              .text{
                .text1{
                  font-size: @font28;
                  line-height: @font28;
                  font-weight: @fontWeight[600];
                  color: @grey[2];
                }
                .text2{
                  font-size: @font14;
                  line-height: 34px;
                  color: @black[700];
                }
              }
            }
            .item_lay{
              .item_flex{
                margin-bottom: 18px;
              }
              .item_con{
                display: flex;
                flex-direction: column;
                line-height: 24px;
                .desc{
                  color: rgba(0,0,0,0.8);
                  font-size: @font14;
                }
                .num{
                  color: rgba(0,0,0,0.8);
                  font-weight: bold;
                  font-size: @font18;
                }
                
                .pay_num{
                  color: rgba(16,16,16,0.8);
                  font-weight: bold;
                  font-size: @font36;
                  line-height: 52px;
                }
                .little_text{
                  font-size: @font12;
                  color: @grey[0];
                }
              }
              
              .btn{
                display: flex;
                justify-content: center;
                align-items: center;
                color: @colorWhite;
                background: @fontColor1;
                border: 1px solid @fontColor1;
                border-radius: 3px;
                height: 55px;
                font-size: @font20;
                margin-top: 32px;
                cursor: pointer;
              }
              .select_flex{
                display: flex;
                flex: 1;
                .el-select{
                  width: 100%;
                }
              }
            }

          }
        }
      }
    }
  }
}
</style>
